import logo from './logo.svg';
import './App.css';
import RouterConfig from './navigation/RouterConfig';
import { BrowserRouter } from "react-router-dom";
import Navbar from './components/Navbar';
import styled from 'styled-components'
import { QueryClient, QueryClientProvider, } from 'react-query'
import { NavBarProvider } from './context/navbar'
import { AuthProvider } from './context/auth';
const RootContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display:flex;
  flex-direction:column;
`

const queryClient = new QueryClient()

function App() {
  return (
    <AuthProvider>
      <QueryClientProvider client={queryClient}>
        <RootContainer>
          <BrowserRouter>
            <NavBarProvider>
              <RouterConfig />
            </NavBarProvider>
          </BrowserRouter>
        </RootContainer>
      </QueryClientProvider>
    </AuthProvider>
  );
}

export default App;
