import React from "react";
import { faEnvelope, faLock } from "@fortawesome/free-solid-svg-icons";
import TextField from "../../components/TextField";
import RoundButton from "../../components/RoundButton";
import TextButton from "../../components/TextButton";
import { useForm, Controller } from "react-hook-form";
import AuthContext from "../../context/auth";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import styled from "styled-components";
import { CAPTCHA_KEY, GOOGLE_CLIENT_ID } from "../../util/CONSTANTS";
import { EMAIL, SURVEY } from "../../navigation/CONSTANT";
import RpplLogo from "./../../assets/newRpplLogo.png";
import { ROOT, CODE } from "../../navigation/CONSTANT";
import RpplMainBG from "./../../assets/rpplMainBG.png";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";

const appearDuration = 300;
const transitionName = `toggleTransition`;

const HLine = styled.div`
  display: flex;
  height: 1px;
  width: 20px;
  border-top: 1px solid #d3d3d3;

  &:hover {
    transform: translateY(-10px);
  }
`;

const ErrorText = styled.p`
  font-family: MontserratMedium;
  font-size: 10px;
  color: red;
  text-align: center;
  padding: 0px;
`;

const DividerText = styled.p`
  font-family: RobotoRegular;
  font-size: 1em;
  margin: 10px 10px 10px 10px;
  color: #d3d3d3;
`;

const WelcomeText = styled.p`
  font-family: GilroyBold;
  font-size: 2.5em;
  text-align: center;
  //margin-left: 17%;
  margin-top: 2px;
  margin-bottom: 0px;
  color: white;
  &.${transitionName}-appear {
    opacity: 0.01;
  }

  &.${transitionName}-appear-active {
    opacity: 1;
    transition: opacity ${appearDuration}ms ease-out;
  }
`;

const SecondWelcome = styled.p`
  font-family: GilroySemiBold;
  font-size: 2em;
  text-align: center;
  //margin-left: 18%;
  margin-top: 2px;
  color: white;
`;

const DividerContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const RootContainer = styled.div`
  width: 100vw;
  height: 100vh;
  position: relative;
`;

const Canvas = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
`;

const LoginContainer = styled.div`
  background-color: transparent;
  display: block;
  position: relative;
  width: 40%;
  /* height: 100%; */
  margin: auto;
  margin-top: 5%;
  overflow: hidden;
  border-radius: 3%;
  &.${transitionName}-appear {
    opacity: 0.01;
  }

  &.${transitionName}-appear-active {
    opacity: 1;
    transition: opacity ${appearDuration}ms ease-out;
  }
`;

const Img = styled.img`
  position: absolute;
  max-width: 300px;
  height: auto;
  vertical-align: middle;
  display: block;
  margin-top: 1%;
  margin-left: 1%;
`;

const Anchor = styled.a`
  margin-top: 2%;
  margin-bottom: 5%;
  text-align: center;
  text-decoration: none;
  font-family: RobotoBold;
  color: white;
  cursor: pointer;
  &:hover {
    color: #4431a7;
  }
  -webkit-transition: all 0.25s linear;
`;
const Headers = styled.a`
  margin-top: 2%;
  margin-bottom: 1%;
  margin-left: 4%;
  text-align: left;
  text-decoration: none;
  font-family: GilroySemiBold;
  color: white;
`;

const Divider = () => (
  <DividerContainer>
    <HLine />
    <DividerText>or</DividerText>
    <HLine />
  </DividerContainer>
);

const schema = yup.object().shape({
  email: yup.string().required("No password provided."),
  password: yup.string().required("No password provided."),
});

const HomeView = ({ history }) => {
  const { useSignIn, useGoogleSignIn } = React.useContext(AuthContext);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const [isHuman, setIsHuman] = React.useState(true);

  const signInMutation = useSignIn();
  const googleSignInMutation = useGoogleSignIn();

  const onSubmit = (values) => {
    if (isHuman) {
      signInMutation.mutate({
        email: values.email,
        password: values.password,
      });
    }
  };

  React.useEffect(() => {
    if (signInMutation.isSuccess || googleSignInMutation.isSuccess) {
      if (signInMutation.data.submitted) {
        window.location.href = "http://www.VotingSmarter.org";
      } else {
        history.push(SURVEY);
      }
    }
  }, [signInMutation, googleSignInMutation]);

  return (
    <RootContainer>
      <Canvas src={RpplMainBG} alt="rpplBG" />
      <Img src={RpplLogo} alt="Rppl Logo" />
      <LoginContainer>
        <CSSTransitionGroup
          transitionName={transitionName}
          transitionAppear={true}
          transitionAppearTimeout={appearDuration}
        >
          <WelcomeText>WELCOME BACK</WelcomeText>
        </CSSTransitionGroup>

        <SecondWelcome>Shop Your Values</SecondWelcome>

        <div
          style={{
            width: "100%",
            flexDirection: "column",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <form
            onSubmit={handleSubmit(onSubmit)}
            style={{
              width: "70%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Headers>Email</Headers>
            <Controller
              control={control}
              name="email"
              render={({ field }) => (
                <TextField
                  error={errors.email?.message}
                  style={{
                    marginTop: "1px",
                    marginBottom: "15px",
                  }}
                  {...field}
                  // placeholder="Email"
                  icon={faEnvelope}
                />
              )}
            />
            {errors.email?.message && (
              <ErrorText>{errors.email?.message}</ErrorText>
            )}
            <Headers>Password</Headers>
            <Controller
              control={control}
              name="password"
              render={({ field }) => (
                <TextField
                  type="password"
                  toggle={true}
                  error={errors.password?.message}
                  style={{
                    marginTop: "1px",
                    marginBottom: "15px",
                  }}
                  {...field}
                  // placeholder="Password"
                  icon={faLock}
                />
              )}
            />
            {errors.password?.message && (
              <ErrorText>errors.password?.message</ErrorText>
            )}
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "20px",
              }}
            ></div>

            {signInMutation.error && (
              <ErrorText>{signInMutation.error}</ErrorText>
            )}
            <RoundButton
              type="submit"
              text="SIGN IN"
              color="white"
              textColor="#4431A7"
            />
            <Anchor href={EMAIL}>Forgot password? </Anchor>

            <TextButton
              onClick={() => history.push(ROOT)}
              text="Don't have an account?"
              ultext="Sign&#8209;Up"
              color="transparent"
              // color="black"
              width="80%"
              padding="25px 0px 0px 0px"
            />
          </form>
        </div>
      </LoginContainer>
    </RootContainer>
  );
};

export default HomeView;
