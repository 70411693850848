import React from 'react'
import Navbar from '../components/Navbar'
import { ROOT, DOWNLOAD, SIGN_IN } from '../navigation/CONSTANT'

const NavbarCtx = React.createContext()
export default NavbarCtx

const dict = {}

dict[ROOT] = [
    {
        text: "Member Sign In",
        href: SIGN_IN
    },
]

dict[SIGN_IN] = [
    {
        text: "Sign Up",
        href: ROOT
    },
]

export const NavBarProvider = (props) => {
    const [isVisible, setVisibility] = React.useState(true)

    const setNavBarVisibility = b => {
        if (b != isVisible) {
            setVisibility(b)
        }
    }

    return (
        <NavbarCtx.Provider
            value={{
                setNavBarVisibility
            }}>

            <Navbar visible={isVisible} dict={dict}/>
            {props.children}
        </NavbarCtx.Provider>
    )
}

