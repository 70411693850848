import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import ErrorText from "../../components/ErrorText";

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* padding: 5%; */
  padding-left: 10%;
  padding-right: 10%;
  margin: 25px 0px 0% 0px;
  /* max-width: 80%; */
  /* align-content: center; */
`;

const EmojiContainer = styled.div`
  flex-direction: column;
  margin: 0px 10px 0px 0px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  transition: transform 0.1s ease-out;
  flex-grow: 1;
  flex-basis: 0;
  cursor: pointer;

  &:hover {
    transform: translateY(-1vh);
  }
  &:active {
    transform: translateY(0.5vh);
  }
`;

const DynamicIcon = styled(FontAwesomeIcon).attrs((props) => ({
  type: "text",
  //   size: props.small ? 5 : undefined,
}))`
  /* height: auto; */
  /* width: 20%; */
  color: "white";

  &:hover {
    color: ${(props) => props.hoverColor};
  }

  -webkit-transition: all 0.5s ease;
`;

const Rating = ({ rating, id, onChange = () => {}, options, error }) => {
  React.useState(() => {
    console.log("form error: ", error);
  }, [error]);

  return (
    <InputContainer error={error}>
      <ErrorText error={error} />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          height: "10%",
        }}
      >
        {options?.consensus &&
          options.consensus.map((props) => (
            <Option
              {...props}
              checkedIdx={rating}
              onCheckHandler={(r) => {
                onChange(r);
              }}
            />
          ))}
      </div>
    </InputContainer>
  );
};

const Option = ({
  icon,
  idx,
  onCheckHandler,
  checkedIdx,
  color,
  text,
  textColor,
  textAlign,
}) => {
  const [isChecked, setChecked] = React.useState(false);

  React.useEffect(() => {
    setChecked(false);
  }, []);

  React.useEffect(() => {
    if (checkedIdx == idx && !isChecked) {
      setChecked(true);
    } else if (isChecked) {
      setChecked(false);
    }
  }, [checkedIdx]);

  return (
    <EmojiContainer
      style={{
        justifyContent: "start",
      }}
      onClick={() => {
        onCheckHandler(idx);
      }}
    >
      <div
        style={{
          display: "flex",
        }}
      >
        <p
          style={{
            color: textColor,
            textAlign: "center",
            height: "40px",
            fontSize: "20px",
            userSelect: "none",
          }}
        >
          {text}
        </p>
      </div>

      <DynamicIcon
        icon={icon}
        hoverColor={color}
        color={isChecked ? color : "white"}
        style={{
          //   height: "50px",
          height: "30%",
          //   height: "20%",
          width: "auto",
        }}
      />

      {/* DEPRECIATED - created custom icon */}
      {/* <FontAwesomeIcon
        icon={icon}
        style={{
          height: "50px",
          width: "auto",
          color: isChecked ? color : "white",
        }}
      /> */}
    </EmojiContainer>
  );
};

export default Rating;
