import React from 'react'
import { Redirect } from 'react-router'
import styled from 'styled-components'
import RpplMainBG from './../../assets/rpplMainBG.png'
import RpplLogo from './../../assets/newRpplLogo.png'


const RootContainer = styled.div`
    width: 100vw;
    height: 100vh;
    position: relative;
`

const Canvas = styled.img`
    position: absolute;
    width: 100%;
    height: 100%;
`


const ContentContainer = styled.div`
    background-color: transparant;
    display: block;
    position: absolute;
    left: 25%
    width: 80%;
    height: 50%;
    margin: 5% 5% 5% 5%;
    overflow:hidden;
    border-radius: 3%;
`
const Anchor = styled.a`
    margin-top: 2%;
    margin-bottom: 5%;
    margin-left: 45%;
    text-align: center;
    text-decoration: none;
    font-family: RobotoBold;
    color: white;
    cursor: pointer;
    &:hover {
        color: blue;
    }
`


const HeaderText1 = styled.h1`
    font-family: GilroySemiBold;
    font-size: 2.0em;
    text-align: center;
    margin-left: 18%;
    margin-top: 2px;
    color: white;
`


const Img = styled.img`
    margin: 10% 0 0 20%;
    max-width: 50%;
    max-height: 50%;
    width: auto;
    height: auto;
`


const RedirectView = () => {

    React.useEffect(() => {
        setTimeout(function () {
            window.location.href = "https://www.rppl.shop"; 
        }, 5000)
    }, [])

    return (
        <RootContainer>
            <Canvas src={RpplMainBG} alt="RpplMainBG" />
            <ContentContainer>
                <Img
                    src={RpplLogo} alt="Rppl Logo" />
                <HeaderText1>We are redirecting you to our mobile site</HeaderText1>

                <Anchor href="https://www.rppl.shop">Not redirected? Click Here! </Anchor>


            </ContentContainer>
        </RootContainer>

    )
}

export default RedirectView