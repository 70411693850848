import React from "react";
import styled from "styled-components";
import {
  faFrownOpen,
  faTired,
  faMeh,
  faSmileBeam,
  faLaugh,
  faExclamation,
} from "@fortawesome/free-solid-svg-icons";
import Rating from "./Rating";
import AuthContext from "../../context/auth";
import { useGetSurvey, usePostSurvey } from "../../util/api/survey";
import Stepper from "./Stepper";
import RpplLogo from "./../../assets/newRpplLogo.png";
import CheckBox from "./CheckBox";
import { DOWNLOAD } from "../../navigation/CONSTANT";
import Modal from "react-modal";
import RpplMainBG from "./../../assets/rpplMainBG.png";
import RoundButton from "../../components/RoundButton";
import ProgressBar from "../../components/ProgressBar";
import RenderButton from "./BackSkipButton";

import "./SurveyStyles.css";

import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import AllSkippedPopup from "./allSkippedPopup/allSkipped";

const appearDuration = 250;
const transitionName = `toggleTransition`;

const RootContainer = styled.div`
  height: 100vh;
  display: flex;
  width: 100%;
  overflow: hidden;
  flex-direction: column;
  justify-content: right;
  align-content: center;
  background-image: url(${RpplMainBG});
`;

const SurveyContainer = styled.div`
  height: 100%;
  &.${transitionName}-appear {
    opacity: 0.01;
  }

  &.${transitionName}-appear-active {
    opacity: 1;
    transition: opacity ${appearDuration}ms ease-out;
  }
`;

const appearDurationIssue = 150;
// const transitionNameIssue = `toggleTransition`;

const IssueTitle = styled.h1`
  margin: 10px 20px 0px 0px;
  font-family: GilroyBold;
  user-select: none;
  color: white;
  font-size: 35pt;
  &.${transitionName}-appear {
    opacity: 0.01;
  }

  &.${transitionName}-appear-active {
    opacity: 1;
    transition: opacity ${appearDurationIssue}ms ease-out;
  }
`;
const IssueStatement = styled.p`
  margin: 20px 0px 0px 0px;
  font-family: GilroyBold;
  user-select: none;
  color: white;
  width: 100%;
  font-size: 25pt;
  text-align: center;
  &.${transitionName}-appear {
    opacity: 0.01;
  }
  &.${transitionName}-appear-active {
    opacity: 1;
    transition: opacity ${appearDurationIssue}ms ease-out;
  }
`;
const IssueContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: space-between;
`;

const consensusOptions = [
  {
    idx: 0,
    icon: faTired,
    color: "#f6645f",
    text: "Strongly Disagree",
    textColor: "white",
  },
  {
    idx: 1,
    icon: faFrownOpen,
    color: "#f58918",
    text: "Disagree",
    textColor: "white",
  },
  {
    idx: 2,
    icon: faMeh,
    color: "#f4e128",
    text: "Neutral",
    textColor: "white",
  },
  {
    idx: 3,
    icon: faSmileBeam,
    color: "#7acb3e",
    text: "Agree",
    textColor: "white",
  },
  {
    idx: 4,
    icon: faLaugh,
    color: "#00b554",
    text: "Strongly Agree",
    textColor: "white",
  },
];

const importantOptions = [
  {
    idx: 0,
    icon: faFrownOpen,
    color: "#f6645f",
  },
  {
    idx: 1,
    icon: faExclamation,
    color: "#f6645f",
  },
];

const options = {
  consensus: consensusOptions,
  importance: importantOptions,
};

const Logo = styled.img`
  display: block;
  max-width: 20%;
  max-height: auto;
  width: auto;
  height: auto;
  margin-top: 2%;
  margin-left: 2%;
  transition: all 1s ease-out;
`;

const Headers = styled.a`
  /* margin-left 4%; */
  font-size: 20pt;
  text-align: left;
  text-decoration: none;
  font-family: GilroySemiBold;
  color: white;
`;

const ProgressHeader = styled.a`
  /* margin-left 4%; */
  font-size: 20pt;
  text-align: left;
  text-decoration: none;
  font-family: GilroyBold;
  color: white;
`;

// Modal styles
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    width: "auto",
    // height: "40%",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    // marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    overflow: "hidden",
    borderRadius: "10px",
    boxShadow: "0 10px 20px rgb(0 0 0 / 15%)",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0)",
  },
};

const SurveyView = ({ history }) => {
  const [modalIsOpen, setIsOpen] = React.useState(true);

  const [skippedErrorIsOpen, setSkippedErrorIsOpen] = React.useState(false);
  // var skippedErrorIsOpen = false

  const { token } = React.useContext(AuthContext);
  const {
    error: getError,
    data: survey,
    status: getStatus,
    isLoading: getIsLoading,
  } = useGetSurvey(token);
  const {
    error: postError,
    mutate,
    isLoading: postIsLoading,
    isSuccess: postIsSuccess,
  } = usePostSurvey(token);

  const [issueIdx, setIssueIdx] = React.useState(0);
  const [answers, setAnswers] = React.useState();

  const [formError, setFormError] = React.useState();

  React.useEffect(() => {
    if (survey) {
      setIssueIdx(0);
      const temp = [];

      survey.issues.forEach((i) => {
        temp.push({
          id: i.id,
          // importance: null,
          // Set importance to TRUE by default
          importance: true,
          consensus: null,
        });
      });

      setAnswers(temp);
    }
  }, [survey]);

  React.useEffect(() => {
    if (postIsSuccess) {
      console.log("calculate complete");
      history.push(DOWNLOAD);
    }
  }, [postIsSuccess]);

  React.useEffect(() => {
    if (postIsLoading) {
      console.log("CALCULATING");
    }
  });

  const resetSurvey = () => {
    setSkippedErrorIsOpen(false);

    setIssueIdx(0);
    const temp = [];
    survey.issues.forEach((i) => {
      temp.push({
        id: i.id,
        // importance: null,
        // Set importance to TRUE by default
        importance: true,
        consensus: null,
      });
    });
    setAnswers(temp);
  };

  const closeSkipError = () => {
    setSkippedErrorIsOpen(false);
  };

  // ******** DEPRECIATED
  // Next button has been replaced by moving to next question on answer selection
  const next = () => {
    if (answers[issueIdx].consensus != null) {
      setIssueIdx(issueIdx + 1);
      setFormError(null);
    } else {
      setFormError("This must be filled in.");
    }
  };
  // *********

  const skip = () => {
    // Set to "Neutral" by default
    // answers[issueIdx].consensus != null;
    onConensusChange(2);
    onImportantChange(false);

    if (issueIdx == survey.issues.length - 1) {
      onSubmit();
    }
  };

  const back = () => {
    const newAnswers = { ...answers };
    newAnswers[issueIdx - 1].consensus = null;
    setAnswers(newAnswers);

    setIssueIdx(issueIdx - 1);
  };

  const onConensusChange = (c) => {
    const newAnswers = { ...answers };
    newAnswers[issueIdx].consensus = c;
    setFormError(null);
    setAnswers(newAnswers);

    // If answer is selected, mark as "important" by default
    onImportantChange(true);

    // If NOT on the last question, increment to next question
    if (!(issueIdx == survey.issues.length - 1)) {
      setIssueIdx(issueIdx + 1);
    } else {
      //  If on last question, submit survey
      onSubmit();
    }
  };

  const onImportantChange = (i) => {
    const newAnswers = { ...answers };
    newAnswers[issueIdx].importance = i;
    setAnswers(newAnswers);
  };

  const onSubmit = () => {
    let allNonImportant = true;
    console.log("answers: ", answers);

    // Checks answers list to confirm at least one question has been answered.
    for (const key in answers) {
      if (answers[key].importance == 1) {
        allNonImportant = false;
      }
    }

    // If all questions are skipped return error message
    if (allNonImportant) {
      // setFormError("Atleast one issue must be important.");

      const newAnswers = { ...answers };
      newAnswers[issueIdx].consensus = null;
      setAnswers(newAnswers);

      setSkippedErrorIsOpen(true);
      return;
    }

    // If last question does not have a consensus value, return error message
    if (answers[issueIdx].consensus == null) {
      setFormError("This must be filled in.");
      return;
    }

    mutate({
      answers: Object.values(answers).map((i) => {
        i.importance = i.importance ? 1 : 0;
        return i;
      }),
    });
  };

  return (
    <RootContainer id="root">
      <Logo src={RpplLogo} style={{ maxWidth: !modalIsOpen ? "10%" : "20%" }} />

      <AllSkippedPopup
        isOpen={skippedErrorIsOpen}
        retakeClick={resetSurvey}
        previousClick={closeSkipError}
      />

      <Modal
        isOpen={modalIsOpen}
        contentLabel="Example Modal"
        style={customStyles}
      >
        <h2
          style={{
            fontFamily: "GilroySemiBold",
            fontSize: "45px",
            letterSpacing: ".1rem",
            textAlign: "center",
            marginTop: "40px",
            userSelect: "none",
          }}
        >
          Welcome to VotingSmarter!
        </h2>
        <div
          style={{
            fontFamily: "Gilroy",
            fontSize: "20px",
            marginBottom: "40px",
            textAlign: "center",
            wordSpacing: ".1rem",
            letterSpacing: ".05rem",
            padding: "0px 40px 0px 40px",
            userSelect: "none",
          }}
        >
          {/* Please complete our matching Quiz at the link below to start seeing
          how you match with companies. The Quiz is made up of partisan
          statements from all sides that are designed to elicit a response. The
          more honest you are, the better we can match you. There are no wrong
          or right answers, only what you believe! */}
          Please take our matching survey in order for us to identify how you
          align with&nbsp;companies. <br />
          <br />
          There are no right or wrong answers, just answer what you believe in!
        </div>

        <div style={{ marginBottom: "30px" }}>
          <RoundButton
            onClick={() => setIsOpen(false)}
            hoverTextColor="white"
            // hoverColor="#774082"
            width="230px"
            height="55px"
            text="Take Survey"
            fontSize="26px"
            borderColor="#783C85"
            textColor="white"
            marginLeft="10px"
            color="#6E27E2"
          />
        </div>
      </Modal>

      {Boolean(
        survey && survey.issues.length > 0 && answers && !modalIsOpen
      ) && (
        <div style={{ height: "100%" }}>
          <CSSTransitionGroup
            transitionName={transitionName}
            transitionAppear={true}
            transitionAppearTimeout={appearDuration}
            style={{ height: "100%" }}
          >
            <SurveyContainer>
              <div
                style={{
                  display: modalIsOpen ? "none" : "flex",
                  // justifyContent: "space-between",
                  justifyContent: "start",
                  height: "100%",
                  paddingBottom: "5%",
                  // marginLeft: "5%",
                  flexDirection: "column",
                  marginTop: "30px",
                  alignItems: "center",
                }}
              >
                <div style={{ width: "70%" }}>
                  <div style={{ height: "90%" }}>
                    <div style={{ width: "100%" }}>
                      <ProgressHeader>Your Progress</ProgressHeader>
                      <ProgressBar
                        id="tonkol"
                        bgcolor="#4431a7"
                        completed={Math.round(
                          (issueIdx / survey.issues.length) * 100
                        )}
                      />
                    </div>

                    <IssueContainer>
                      <CSSTransitionGroup
                        key={issueIdx}
                        transitionName={transitionName}
                        transitionAppear={true}
                        transitionLeaveTimeout={300}
                      >
                        <IssueTitle>{survey.issues[issueIdx].title}</IssueTitle>
                      </CSSTransitionGroup>
                    </IssueContainer>

                    {/* ****** DEPRECIATED ****** */}
                    {/* <CheckBox
                    toggled={
                      answers[issueIdx]
                        ? answers[issueIdx].importance == 1
                        : false
                    }
                    onChange={onImportantChange}
                    tickedColor="#804c8b"
                    color="white"
                    text="Important"
                    id={issueIdx}
                  /> */}
                    {/* *************************** */}

                    <IssueContainer>
                      <CSSTransitionGroup
                        key={issueIdx}
                        transitionName={transitionName}
                        transitionAppear={true}
                        transitionLeaveTimeout={300}
                      >
                        <IssueStatement>
                          {survey.issues[issueIdx].description}
                        </IssueStatement>
                      </CSSTransitionGroup>
                    </IssueContainer>

                    <Rating
                      rating={
                        answers[issueIdx] ? answers[issueIdx].consensus : null
                      }
                      error={formError}
                      options={options}
                      id={issueIdx}
                      onChange={onConensusChange}
                    />
                  </div>

                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <RenderButton
                      onBack={back}
                      // onNext={next}
                      onNext={skip}
                      onSubmit={onSubmit}
                      isLast={issueIdx == survey.issues.length - 1}
                      isFirst={issueIdx == 0}
                    />
                  </div>
                </div>
              </div>
            </SurveyContainer>
          </CSSTransitionGroup>
        </div>
      )}
    </RootContainer>
  );
};

export default SurveyView;
