
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

const Container = styled.div`
	border: ${(props) => props.border || "1px solid #d3d3d3"};
	border-radius: 5px;
	display: flex;
	height: 40px;
	justify-content: space-between;
	align-items: center;
	padding-left: 20px;
	padding-right: 20px;
	background-color: white;
`;

const TextInput = styled.input`
	outline: none;
	border: 0px;
	height: 80%;
	width: 100%;
	font-family: robotoRegular;
	color: white;
`;

const LegacyTextField = ({
	icon,
	type = "text",
	placeholder = "",
	style,
	value,
	onChange,
	error,
	toggle,
}) => {
	const [isOn, setOn] = React.useState(false);

	return (
		<Container
			style={{
				...style,
			}}
			border={error && "1px solid red"}
		>
			<TextInput
				value={value}
				onChange={onChange}
				type={toggle && isOn ? "text" : type}
				placeholder={placeholder}
				style={{
					outline: "none",
					border: "0px",
					height: "80%",
					width: "90%",
					fontFamily: "robotoRegular",
					color: "#B8B8B8",
				}}
			/>
			{icon && (
				<FontAwesomeIcon
					onClick={() => setOn(!isOn)}
					icon={icon}
					color={toggle & isOn ? "#774082" : "#d3d3d3"}
					style={{
						height: "100%",
					}}
				/>
			)}
		</Container>
	);
};

export default LegacyTextField;