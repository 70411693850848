import React from "react";

const ProgressBar = (props) => {
  const { bgcolor, completed } = props;

  const containerStyles = {
    height: 15,
    width: "100%",
    backgroundColor: "rgba(240, 240, 238, 0.4)",
    borderRadius: 50,
    marginTop: 10,
    marginBottom: 40,
    boxShadow: "0 10px 20px rgb(0 0 0 / 15%)",
  };

  const fillerStyles = {
    height: "100%",
    width: `${completed}%`,
    backgroundColor: bgcolor,
    transition: "width 1s ease-in-out",
    borderRadius: "inherit",
    textAlign: "right",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  };

  const labelStyles = {
    padding: "15px",
    color: "white",
    fontWeight: "bold",
    justifyContent: "center",
  };

  return (
    <div style={containerStyles}>
      <div style={fillerStyles}>
        {/* <span style={labelStyles}>{`${completed}%`}</span> */}
      </div>
    </div>
  );
};

export default ProgressBar;
