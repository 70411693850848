import React from "react";
import _ from "lodash";
import styled from "styled-components";
import RoundButton from "../components/RoundButton";
import ErrorText from "./ErrorText";
const RootContainer = styled.div`
	display: flex;
	flex-direction: column;
	justiy-content: center;
	align-items: center;
	width: 100%;
`;

const CodeContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin: 10px;
	width: 90%;
`;

const InvisibleTextInput = styled.input`
	width: 0;
	height: 0;
	border: 0;
	opacity: 0;
`;

const ButtonContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
`

const TextButton = styled.p`
	margin: 20px;
	font-fammily: RobotoBold;
	cursor: pointer;
	color: #A9A9A9;

	&:hover {
		color: blue;
	}
`

const CodeForm = ({ onSubmit, error, length, onResend }) => {
	const [data, setData] = React.useState("");
	const inputRef = React.useRef(null);
	const handleSubmit = () => {
		if (data.length == length) {
			onSubmit(data);
		}
	};

	React.useEffect(() => {
		if (inputRef.current) {
			inputRef.current.focus();
		}
	}, []);

	const handleClick = () => {
		inputRef.current.focus();
	};

	return (
		<RootContainer id="code-form-root-container">
			<CodeContainer id="code-form-code-container">
				{_.times(length, (i) => (
					<Code
						key={i}
						value={i >= data.length ? "" : data[i]}
						onClick={handleClick}
					/>
				))}
			</CodeContainer>
			<InvisibleTextInput
				maxLength={`${length}`}
				ref={inputRef}
				type="number"
				value={data}
				onChange={(e) => {
					const str = e.target.value;
					if (str.length <= length) {
						console.log(str);
						setData(str);
					}
				}}
			/>
			{error && <ErrorText error={error} />}
			<ButtonContainer>
				<TextButton onClick={onResend}>Resend Code</TextButton>
				<TextButton onClick={handleSubmit}>Verify Code</TextButton>
			</ButtonContainer>
		</RootContainer>
	);
};

const CharacterContainer = styled.div`
	width: 81px;
	height: 91px;
	margin: 0px 10px 0px 10px;
	background: #ffffff;
	border: 5px solid #d3d3d3;
	box-sizing: border-box;
	border-radius: 20px;

	display: flex;
	flex-direction: column;
	justify-content: center;
`;

const CodeCharacter = styled.h1`
	margin: 0;
	height: 40px;
	text-align: center;
	font-family: RobotoBold;
`;

const Code = ({ value, onClick }) => {
	return (
		<CharacterContainer onClick={onClick}>
			<CodeCharacter>{value}</CodeCharacter>
		</CharacterContainer>
	);
};

export default CodeForm;
