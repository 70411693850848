import React from "react";
import CodeForm from "../../components/CodeForm";
import styled from "styled-components";
import { useSendEmailVerificationCode } from "../../util/api/auth";
import AuthContext from "../../context/auth";
import KeyIcon from "../../assets/key.svg";
import { useHistory } from "react-router-dom";
import { SURVEY } from "../../navigation/CONSTANT";
import _ from "lodash";
const RootContainer = styled.div`
	width: 100vw;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

const DescriptionContainer = styled.div`
	display: flex;
	flex-direction: row;
`;

const Icon = styled.img`
	width: 30px;
	height: auto;
	margin-right: 20px;
`;

const Title = styled.h2`
	font-family: RobotoBold;
`;

const Description = styled.p`
	font-family: RobotoBold;
	font-size: 15px;
`;

const Verification = ({ history }) => {
	const { token, useVerifyEmail } = React.useContext(AuthContext);
	const sendCodeCreationMutation = useSendEmailVerificationCode(token);
	const verifyEmailMutation = useVerifyEmail(token);

	React.useEffect(() => {
		sendCodeCreationMutation.mutate();
	}, []);

	React.useEffect(() => {
		if (verifyEmailMutation.isSuccess) {
			
			history.push(SURVEY);
		}
	}, [verifyEmailMutation]);

	return (
		<RootContainer>
			<Title>Verify your Identity</Title>
			<DescriptionContainer>
				<Icon src={KeyIcon} />
				<Description>
					Enter the code we have sent to your email
				</Description>
			</DescriptionContainer>
			<CodeForm
				error={
					_.isEmpty(verifyEmailMutation.error)
						? null
						: verifyEmailMutation.error
				}
				length={4}
				onResend={() => sendCodeCreationMutation.mutate()}
				onSubmit={(code) => {
					verifyEmailMutation.mutate(code);
				}}
			/>
		</RootContainer>
	);
};

export default Verification;
