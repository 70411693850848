import React from "react";
import styled from "styled-components";

const El = styled.p`
  font-family: RobotoBold;
  /* font-size: 10px; */
  /* color: red; */
  /* text-align: center; */
  /* padding: 0px; */
`;

const ErrorText = ({ error }) => {
  /* console.log("error text: ", error) */
  if (error) {
    return <El>{error}</El>;
  }
  return null;
};

export default ErrorText;
