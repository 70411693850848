import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

const ButtonEl = styled.button`
  height: ${(props) => props.height || "auto"};
  width: ${(props) => props.width || "100%"};
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: RobotoBold;
  font-size: 16px;
  border: 0px;
  border-radius: 20px;
  background-color: ${(props) => props.backgroundColor};
  color: white;
  padding: ${(props) => props.padding};
  margin-left: 10%;
  margin-right: 10%;

  &:hover {
    /* background-color: ${(props) => props.hoverColor || "purple"}; */
    color: ${(props) => props.hoverTextColor || "#4431A7"};
    /* color: ${(props) => props.hoverTextColor || "#2e2272"}; */
    cursor: pointer;
  }
  -webkit-transition: all 0.25s linear;
  transition: all 0.25s linear;
`;

const TextButton = ({
  onClick = () => {},
  width,
  color,
  text,
  padding,
  ultext,

  height,
}) => {
  return (
    <ButtonEl
      onClick={onClick}
      height={height}
      width={width}
      textColor="white"
      backgroundColor={color}
      padding={padding}
    >
      <span>
        {text}
        <u style={{ padding: "0rem 0rem 0rem .3rem" }}>{ultext}</u>
      </span>
    </ButtonEl>
  );
};

export default TextButton;
