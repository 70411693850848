import React from "react";
import styled from "styled-components";
import "./SkipErrorButton.css";

const ButtonEl = styled.button`
  width: 220px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: GilroyBold;
  font-weight: bold;
  letter-spacing: 1px;
  font-size: 20px;
  border: 2px solid #4431a7;
  border-radius: 25px;
  background-color: white;
  color: #4431a7;
  margin: 0 15px;
  user-select: none;
  /* margin: 0px 5px; */

  &:hover {
    background-color: #4431a7;
    color: white;
    cursor: pointer;
  }
  -webkit-transition: all 0.25s linear;
`;

const SkipErrorButton = ({ onClick = () => {}, buttonCaption }) => {
  return (
    <ButtonEl onClick={onClick}>
      <text style={{ userSelect: "none" }}>{buttonCaption}</text>
    </ButtonEl>
  );
};

export default SkipErrorButton;
