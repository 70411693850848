import React from "react";
import { useQuery, useMutation } from "react-query";
import axios from "../axios";
import { getToken } from "../storage";

export const useSignIn = (
	callback = async (e, token, oldToken, emailVerified) => {}
) =>
	useMutation("signIn", async ({ email, password }) => {
		try {
			const res = await axios(`/user/login`, {
				method: "post",
				data: {
					email: email,
					password: password,
				},
			});
			await callback(
				email,
				res.data.token,
				getToken(),
				res.data.emailVerified
			);
			const { data: submitted } = await axios.get(`/user/submitted`, {
				headers: {
					"Content-Type": "application/json",
					Authorization: res.data.token,
				},
			});
			return {
				...res,
				submitted
			};
		} catch (err) {
			throw err.response.data;
		}
	});

export const useSignUp = () =>
	useMutation("signUp", async ({ email, password }) => {
		try {
			const res = await axios.post(`/user/register`, {
				email: email,
				password: password,
			});
			return res;
		} catch (err) {
			throw err.response.data;
		}
	});

export const useGoogleSignIn = (callback = async (e, t) => {}) =>
	useMutation("googleSignIn", async (googleData) => {
		try {
			const res = await axios.post(
				`/user/google_sign_in`,
				{
					token: googleData.tokenId,
				},
				{
					headers: {
						"Content-Type": "application/json",
					},
				}
			);
			const { email, token } = res.data;
			await callback(email, token);
			return res.data;
		} catch (err) {
			console.log("err: ", err);
			throw err.response.data;
		}
	});

export const logOut = async (oldToken) => {
	try {
		await axios.post("user/logout", {
			headers: {
				Authorization: oldToken,
			},
		});
	} catch (err) {
		console.log("logout error: ", err);
		throw err;
	}
};

export const useSendEmailVerificationCode = (token) =>
	useMutation("sendEmailVerification", async () => {
		try {
			const res = await axios.post(
				`/user/verify/email`,
				{},
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: token,
					},
				}
			);
			return res;
		} catch (err) {
			throw err.response.data;
		}
	});

export const useVerifyEmail = (token, callback) =>
	useMutation("sendEmailVerification", async (code) => {
		try {
			const res = await axios.put(
				`/user/verify/email`,
				{
					code: code,
				},
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: token,
					},
				}
			);
			await callback();
			return res;
		} catch (err) {
			throw err.response.data;
		}
	});

export const useSendPasswordRecoveryCode = () =>
	useMutation("sendPasswordRecoveryCode", async (email) => {
		try {
			const res = await axios.post(
				`/user/password`,
				{
					email: email,
				},
				{
					headers: {
						"Content-Type": "application/json",
					},
				}
			);
			return res;
		} catch (err) {
			throw err.response.data;
		}
	});

export const useResetPassword = () =>
	useMutation(
		"sendPasswordRecoveryCode",
		async ({ email, code, password }) => {
			try {
				const res = await axios.put(
					`/user/password`,
					{
						email: email,
						code: code,
						password: password,
					},
					{
						headers: {
							"Content-Type": "application/json",
						},
					}
				);
				return res;
			} catch (err) {
				throw err.response.data;
			}
		}
	);

