

import React from "react";
import styled from "styled-components";

const RootContainer = styled.div`
    position: relative;
    height: 30px;
    width: 85px;
    align-items: left;
    
`;

const SwitchInput = styled.input`
    height: 0;
    width: 0;
    visibility: hidden;
`;

const SwitchLabel = styled.label`
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 85px;
    height: 30px;
    border-radius: 100px;
    border: 2px solid white;
    backgroundColor: white;
    position: relative;
    transition: background-color 0.2s;
`;

const ToggleText = styled.p`
    font-family: MontserratMedium;
    font-size: 0.9em;
    margin-left: 5px;
    margin-right: 5px;
    color: white;
`

const SwitchButton = styled.span`
    position: absolute;
    top: 2px;
    left: 4px;
    width: 20px;
    height: 20px;
    border-radius: 45px;
    transition: 0.2s;
    background: white;
    backgroundColor: white;
    border: 3px solid white;



    box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
    ${SwitchInput}:checked + ${SwitchLabel} & {
        left: calc(100% - 2px);
        transform: translateX(-100%);
        background: white;

    }

    
`;


const CheckBox = ({ id, toggled, onChange }) => {
    return (
        <div style ={{
            height: '30px;'
        }}>
            <SwitchInput
                className="switch-checkbox"
                id={id}
                type="checkbox"
                checked={toggled}
                onChange={() => onChange(!toggled)}
            />
            <SwitchLabel className="switch-label" htmlFor={id}>
                <SwitchButton className="switch-button" />
                <ToggleText>Yes</ToggleText>
                <ToggleText>No</ToggleText>
            </SwitchLabel>
            
        </div>
    );
};

export default CheckBox;