import React from "react";
import styled from "styled-components";
import RoundButton from "../../components/RoundButton";

const ButtonContainer = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  // justify-content: flex-start;
  justify-content: center;
  align-items: center;
  width: 70%;
  gap: 2%;
`;

const RenderButton = ({
  onBack,
  onNext,
  onSubmit,
  isLast,
  isFirst,
  disableNext,
}) => {
  // Button Choices for first question
  if (isFirst) {
    return (
      <ButtonContainer>
        <RoundButton
          onClick={onNext}
          hoverTextColor="white"
          // hoverColor="#774082"
          width="200px"
          text="Skip"
          fontSize="16pt"
          disabled={disableNext}
          borderColor="#783C85"
          textColor="#4431A7"
          marginLeft="5px"
        />
      </ButtonContainer>
    );
  }
  // Button choices for last question
  if (isLast) {
    return (
      <ButtonContainer>
        <RoundButton
          onClick={onBack}
          hoverTextColor="white"
          // hoverColor="#774082"
          width="200px"
          text="Back"
          fontSize="16pt"
          borderColor="#783C85"
          textColor="#4431A7"
        />
        <RoundButton
          // disabled={disableNext}
          // onClick={onSubmit}
          onClick={onNext}
          hoverTextColor="white"
          // hoverColor="#774082"
          width="200px"
          text="Skip"
          fontSize="16pt"
          borderColor="#783C85"
          textColor="#4431A7"
        />
      </ButtonContainer>
    );
  }
  // Button choices for rest of questions
  return (
    <ButtonContainer>
      <RoundButton
        onClick={onBack}
        hoverTextColor="white"
        // hoverColor="#6E27E2"
        width="200px"
        text="Back"
        fontSize="16pt"
        borderColor="#783C85"
        textColor="#4431A7"
        marginLeft="10px"
      />
      <RoundButton
        disabled={disableNext}
        onClick={onNext}
        hoverTextColor="white"
        // hoverColor="#774082"
        width="200px"
        fontFamily="MontserratBold"
        text="Skip"
        fontSize="16pt"
        borderColor="#783C85"
        textColor="#4431A7"
      />
    </ButtonContainer>
  );
};

export default RenderButton;
