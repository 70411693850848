import React from "react";
import Modal from "react-modal";
import "./allSkipped.css";
import { FiAlertCircle } from "react-icons/fi";
import SkipErrorButton from "./components/SkipErrorButton";
import "./allSkipped.css";

const AllSkippedPopup = (props) => {
  return (
    <Modal
      isOpen={props.isOpen}
      className="SkippedPopup"
      overlayClassName={"SkippedPopupOverlay"}
    >
      <FiAlertCircle className="icon" />
      <h2>Please respond to at least one question.</h2>

      <div className="buttons">
        <SkipErrorButton
          buttonCaption={"Retake Survey"}
          onClick={props.retakeClick}
        />
        <SkipErrorButton
          buttonCaption={"Previous Question"}
          onClick={props.previousClick}
        />
      </div>
    </Modal>
  );
};

export default AllSkippedPopup;
