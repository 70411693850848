import React from "react";
import styled from "styled-components";
import RpplLogo from "./../../assets/newRpplLogo.png";
import VSBlack from "./../../assets/VSBlack.png";
import RoundButton from "../../components/RoundButton";
import RpplMainBG from "./../../assets/rpplMainBG.png";
import ProgressBar from "../../components/ProgressBar";
import Modal from "react-modal";
import "./index.css";
import { IoExtensionPuzzleSharp } from "react-icons/io5";
import { BsPinFill } from "react-icons/bs";

const Logo = styled.img`
  display: block;
  max-width: 20%;
  max-height: auto;
  width: auto;
  height: auto;
  margin-top: 2%;
  margin-left: 2%;
  transition: all 1s ease-out;
`;

const RootContainer = styled.div`
  height: 100vh;
  display: flex;
  width: 100%;
  overflow: hidden;
  flex-direction: column;
  justify-content: right;
  align-content: center;
  background-image: url(${RpplMainBG});
`;

const appearDuration = 250;
const transitionName = `toggleTransition`;
const appearDurationIssue = 150;

const SurveyContainer = styled.div`
  height: 100%;
`;

const ProgressHeader = styled.a`
  font-size: 20pt;
  text-align: left;
  text-decoration: none;
  font-family: GilroyBold;
  color: white;
`;

const CompletionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: space-between;
  text-align: center;
`;

const CompletionHeader = styled.h1`
  margin: 50px 20px 0px 0px;
  font-family: GilroyBold;
  color: white;
  font-size: 35pt;
`;

const CompletionCaption = styled.p`
  margin: 30px 0px 0px 0px;
  font-family: GilroyBold;
  color: white;
  width: 100%;
  font-size: 22pt;
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const CompletionPopup = styled.p`
  font-family: GilroySemibold;
  font-size: 15pt;
  text-align: center;
  padding: 10px 30px;
`;

// Modal styles
const customStyles = {
  content: {
    position: "absolute",
    top: "1%",
    left: "auto",
    right: "1%",
    bottom: "auto",
    width: "350px",
    height: "200px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: "10px",
    backgroundColor: "#fff",
    padding: "10px 10px 10px 10px",
    overflow: "hidden",
    outline: "none",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0)",
  },
};

const ModalBtn = styled.button`
  background-color: white;
  border: none;
  color: grey;
  position: absolute;
  right: 10px;
  font-size: 20px;
`;

const DownloadView = () => {
  const [modalIsOpen, setIsOpen] = React.useState(true);

  return (
    <RootContainer id="root">
      <Logo src={RpplLogo} style={{ maxWidth: "10%" }} />
      <div style={{ height: "100%" }}>
        <SurveyContainer>
          <div
            style={{
              justifyContent: "start",
              height: "100%",
              paddingBottom: "5%",
              flexDirection: "column",
              marginTop: "30px",
              alignItems: "center",
            }}
          >
            <div style={{ width: "70%", margin: "auto" }}>
              <div style={{ height: "90%" }}>
                <div style={{ width: "100%" }}>
                  <ProgressHeader>Your Progress</ProgressHeader>
                  <ProgressBar id="tonkol" bgcolor="#9DDC94" completed={100} />
                </div>
                <CompletionContainer>
                  <CompletionHeader className="completionHeader">
                    Complete!
                  </CompletionHeader>
                </CompletionContainer>
                <CompletionContainer>
                  {/* <CompletionCaption>
                    Your responses have been saved. <br /> Please download the
                    VotingSmarter extension in the Chrome Store
                  </CompletionCaption> */}

                  <CompletionCaption>
                    <p className="captionFirstLine">
                      Your responses have been saved.
                    </p>
                    <p className="captionSecondLine">
                      Open the VotingSmarter extension while shopping to see how
                      well you match with each brand you visit!
                    </p>
                  </CompletionCaption>
                </CompletionContainer>
                <Modal
                  className="downloadPopup"
                  isOpen={modalIsOpen}
                  style={customStyles}
                  shouldFocusAfterRender={false}
                >
                  <ModalBtn onClick={() => setIsOpen(false)}>x</ModalBtn>
                  <Logo src={VSBlack} style={{ maxWidth: "60%" }} />
                  <CompletionPopup>
                    Click on the{" "}
                    <span>
                      <IoExtensionPuzzleSharp color="#6e27e2" />
                    </span>{" "}
                    to pin{" "}
                    <span>
                      <BsPinFill color="#6e27e2" />
                    </span>{" "}
                    VotingSmarter to your browser
                  </CompletionPopup>
                </Modal>
              </div>

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "space-between",
                  paddingTop: "50px",
                }}
              >
                {/* <RoundButton
                  onClick={() => {
                    window.location.href =
                      "https://chrome.google.com/webstore/detail/votingsmarter/hkolhebbbgddjgekpkohnollofaijncj?hl=en-US";
                  }}
                  hoverTextColor="white"
                  width="200px"
                  text="Download"
                  fontSize="16pt"
                  borderColor="#783C85"
                  textColor="#4431A7"
                  marginLeft="5px"
                /> */}
              </div>
            </div>
          </div>
        </SurveyContainer>
      </div>
      )
    </RootContainer>
  );
};

export default DownloadView;
