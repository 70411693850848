import React from 'react';
import styled from 'styled-components'

const Line = styled.div`
    width: 35px;
    height: 3px;
    margin: 5px 0px 5px 0px;
    background-color: ${props => props.color || '#774082'};
    -webkit-transition: all 0.25s linear;
`

const Text = styled.p`
    margin: 5px 8px 5px 8px;
    color: ${props => props.color || 'black'};
    font-family: MontserratBold;
    text-align: center;
    font-size: 25px;
    -webkit-transition: all 0.25s linear;
`

const RootContainer = styled.div`
    &:hover {
        overflow: auto;
    }
    width: 100%;
    margin: 0% 0% 0%;
    overflow: hidden;
    ::-webkit-scrollbar-track {
        background-color: transparent;
    }
    display: flex;
    flex-direction: row;
    ::-webkit-scrollbar-thumb {
        background-color: #d6dee1;
        border-radius: 20px;
        border: 6px solid transparent;
        background-clip: content-box;
    }
`

const Stepper = ({ currentIdx, issues }) => {
    return (
        <RootContainer
        >
            {issues.map(({ title }, idx) => (
                <Step
                    isActive={currentIdx == idx}
                    isFirst={idx == 0}
                    title={title}
                />
            ))
            }
        </RootContainer>
    )
}

const Step = ({ isFirst, isActive, title }) => {
    if (isFirst) {
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                <Text color={isActive ? '#519dd2' : '#774082'} >{title}</Text>

            </div >
        )
    }
    else {
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                <Line color={isActive ? '#774082' : '#774082'} />
                <Text color={isActive ? '#519dd2' : '#774082'}>{title}</Text>

            </div>
        )
    }
}

export default Stepper