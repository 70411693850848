import React from "react";
import {
	Switch,
	Route,
	useLocation,
	Redirect,
	Router,
	useHistory,
} from "react-router-dom";
import {
	DOWNLOAD,
	REDIR,
	ROOT,
	SIGN_IN,
	SURVEY,
	CODE,
	EMAIL,
	PASSWORD,
	HINT,
} from "./CONSTANT";

import styled from "styled-components";
import NavbarCtx from "../context/navbar";
import { getToken } from "../util/storage";
import AuthContext from "../context/auth";

import HomeView from "../pages/home";
import DownloadView from "../pages/download";
import SignInView from "../pages/sign_in";
import SurveyView from "../pages/survey";
import RedirectView from "../pages/redirect";
import VerificationView from "../pages/verification";
import EmailView from "../pages/password/email";
import PasswordRecoveryView from "../pages/password/reset";
import HintView from "../pages/hint";

const RootContainer = styled.div`
	display: flex;
	flex: 1;
`;

const excludeNavBarRoutes = [DOWNLOAD, SURVEY];

const RouterConfig = () => {
	const { setNavBarVisibility } = React.useContext(NavbarCtx);
	let { pathname } = useLocation();
	setNavBarVisibility(false);
	// React.useEffect(() => {
	//   if (excludeNavBarRoutes.includes(pathname)) {
	//     setNavBarVisibility(false)
	//   } else {
	//     setNavBarVisibility(true)
	//   }
	// }, [pathname])

	return (
		<RootContainer>
			<Switch>
				<Route exact path={ROOT} component={HomeView} />
				<Route path={SIGN_IN} component={SignInView} />
				<Route path={REDIR} component={RedirectView} />
				<Route path={CODE} component={VerificationView} />
				<Route path={EMAIL} component={EmailView} />
				<Route path={PASSWORD} component={PasswordRecoveryView} />

				<PrivateRoute path={DOWNLOAD} component={DownloadView} />
				<PrivateRoute path={SURVEY} component={SurveyView} />
				<PrivateRoute path={HINT} component={HintView} />
			</Switch>
		</RootContainer>
	);
};

function PrivateRoute({ component: Component, ...rest }) {
	const { data } = React.useContext(AuthContext);

	React.useEffect(() => {
	},[data])

	if (!data?.email) {
		return (
			<Route
				{...rest}
				render={(props) => (
					<Redirect
						to={{
							pathname: "/",
							state: { from: props.location },
						}}
					/>
				)}
			/>
		);
	} else if (!data?.emailVerified) {
		return (
			<Route
				{...rest}
				render={(props) => (
					<Redirect
						to={{
							pathname: `${CODE}`,
							state: { from: props.location },
						}}
					/>
				)}
			/>
		);
	}
	return <Route {...rest} render={(props) => <Component {...props} />} />;
}

export default RouterConfig;
