import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

const ButtonEl = styled.button`
  height: ${(props) => props.height || "45px"};
  width: ${(props) => props.width || "100%"};
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: GilroyBold;
  letter-spacing: 1px;
  font-size: ${(props) => props.fontSize || "17px"};
  border: 0px;
  border-radius: 20px;
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.textColor};
  user-select: none;

  &:hover {
    background-color: ${(props) => props.hoverColor || "#4431A7"};
    color: ${(props) => props.hoverTextColor || "white"};
    cursor: pointer;
  }
  -webkit-transition: all 0.25s linear;
`;

const RoundButton = ({
  onClick = () => {},
  width,
  color,
  hoverColor,
  hoverTextColor,
  text,
  icon,
  textColor,
  height,
  fontSize,
}) => {
  return (
    <ButtonEl
      onClick={onClick}
      height={height}
      width={width}
      textColor={textColor}
      backgroundColor={color}
      hoverColor={hoverColor}
      hoverTextColor={hoverTextColor}
      fontSize={fontSize}
    >
      {icon && (
        <FontAwesomeIcon
          icon={icon}
          color="#d3d3d3"
          style={{
            color: "blue",
            marginRight: "10px",
            height: "100%",
          }}
        />
      )}
      {text}
    </ButtonEl>
  );
};

export default RoundButton;
