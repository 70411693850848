import React from "react";
import CodeForm from "../../components/CodeForm";
import styled from "styled-components";
import { faLock } from "@fortawesome/free-solid-svg-icons";

import {
	useResetPassword,
	useSendPasswordRecoveryCode,
} from "../../util/api/auth";
import AuthContext from "../../context/auth";
import KeyIcon from "../../assets/key.svg";
import { useHistory, useLocation } from "react-router-dom";
import { SIGN_IN, SURVEY } from "../../navigation/CONSTANT";
import * as yup from "yup";
import _ from "lodash";
import queryString from "query-string";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import TextField from "../../components/TextField";
import ErrorText from "../../components/ErrorText";
import RpplMainBG from './../../assets/rpplMainBG.png'



const Canvas = styled.img`
    position: absolute;
    width: 100%;
    height: 100%;
`


const RootContainer = styled.div`
	width: 100vw;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

const DescriptionContainer = styled.div`
	display: flex;
	flex-direction: row;
`;

const Icon = styled.img`
	width: 30px;
	height: auto;
	margin-right: 20px;
`;

const Title = styled.h2`
	font-family: RobotoBold;
`;

const Description = styled.p`
	font-family: RobotoBold;
	font-size: 15px;
`;

const schema = yup.object().shape({
	password: yup
		.string()
		.required("No password provided.")
		.min(8, "Password is too short - should be 8 chars minimum.")
		.matches(/[a-zA-Z]/, "Password can only contain Latin letters."),
	passwordConfirmation: yup
		.string()
		.oneOf([yup.ref("password"), null], "Passwords must match"),
});

const PasswordRecoveryView = ({ history }) => {
	const location = useLocation();
	const resetPasswordMutation = useResetPassword();
	const requestPasswordRecoveryMutation = useSendPasswordRecoveryCode();
	const email = queryString.parse(location.search).email;

	const {
		control,
		handleSubmit,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			password: "",
			passwordConfirmation: "",
		},
	});

	React.useEffect(() => {
		if (resetPasswordMutation.isSuccess) {
			history.push(SIGN_IN);
		} else {
		}
	}, [resetPasswordMutation]);

	const onSubmit = (code, { password }) => {
		resetPasswordMutation.mutate({
			email: email,
			code: code,
			password: password,
		});
	};

	const onResend = () => {
		requestPasswordRecoveryMutation.mutate(email);
	};

	return (
		<RootContainer>
			
			<Title>Password Recovery</Title>
			<form>
				<Controller
					control={control}
					name="password"
					render={({ field }) => (
						<TextField
							toggle={true}
							type="password"
							error={errors.password?.message}
							style={{
								marginTop: "15px",
								marginBottom: "15px",
							}}
							{...field}
							placeholder="Password"
							icon={faLock}
						/>
					)}
				/>
				<ErrorText error={errors.password?.message} />

				<Controller
					control={control}
					name="passwordConfirmation"
					render={({ field }) => (
						<TextField
							toggle={true}
							type="password"
							error={errors.passwordConfirmation?.message}
							style={{
								marginTop: "15px",
								marginBottom: "15px",
							}}
							{...field}
							placeholder="Confirm Password"
							icon={faLock}
						/>
					)}
				/>
				<ErrorText error={errors.passwordConfirmation?.message} />
			</form>
			<DescriptionContainer>
				<Icon src={KeyIcon} />
				<Description>
					Enter the code we have sent to your email
				</Description>
			</DescriptionContainer>
			<CodeForm
				error={
					_.isEmpty(resetPasswordMutation.error)
						? null
						: resetPasswordMutation.error
				}
				length={4}
				onResend={onResend}
				onSubmit={(code) => {
					handleSubmit((values) => onSubmit(code, values))();
				}}
			/>
		</RootContainer>
	);
};

export default PasswordRecoveryView;
