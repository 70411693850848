    /*global chrome*/
import React from "react";
import { storeToken, getToken, getEmail, getUserData, storeUserData } from "../util/storage";
import {
	useSignIn as useSignInApi,
	useSignUp as useSignUpAPI,
	useGoogleSignIn as useGoogleSignInAPI,
	useVerifyEmail as useVerifyEmailAPI,
} from "../util/api/auth";
import { logOut } from "../util/api/auth";
const AuthContext = React.createContext();
export default AuthContext;
export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
export const EXTENSION_ID = process.env.REACT_APP_EXTENSION_ID;

export const AuthProvider = ({ children }) => {
	const [token, setToken] = React.useState(getToken());
	const [data, setData] = React.useState(getUserData());

	const onSignIn = async (e, t, oldToken, emailVerified) => {
		storeToken(t);
		setToken(t);
		setData({
			email: e,
			emailVerified: emailVerified,
		});
		
		storeUserData(e, emailVerified)
		if (chrome.runtime) {
			await chrome.runtime.sendMessage(EXTENSION_ID, {token: token}, () => {});
		}
	};

	const onEmailVerified = async () => {
		
		setData({
			...data,
			emailVerified: true,
		});
	};

	const useSignIn = () => useSignInApi(onSignIn);

	const useGoogleSignIn = () => useGoogleSignInAPI(onSignIn);

	const useVerifyEmail = () => useVerifyEmailAPI(token, onEmailVerified);

	const useSignUp = () => useSignUpAPI();
	return (
		<AuthContext.Provider
			value={{
				useSignIn,
				useSignUp,
				useGoogleSignIn,
				token,
				data,
				useVerifyEmail,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};
