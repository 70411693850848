export const storeToken = t => {
    localStorage.setItem('token', t)
}

export const getToken = () => {
    return localStorage.getItem('token');
}

export const getEmail = () => {
    return localStorage.getItem('email');
}

export const storeUserData = (email, emailVerified) => {
    localStorage.setItem('email', email)
    localStorage.setItem('emailVerified', emailVerified)
}

export const getUserData = () => ({
    email: localStorage.getItem('email'),
    emailVerified: localStorage.getItem('emailVerified')
})

export const setEmail = email => {
    localStorage.setItem('email', email)
}
