import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

const Container = styled.div`
  border-radius: 50px;
  display: flex;
  height: 70px;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #a07de4;
`;

const TextInput = styled.input`
  outline: none;
  border: 0px;
  height: 80%;
  width: 100%;
  font-size: 15pt;
  font-family: robotoRegular;
  color: white;
  background-color: white;
  -webkit-box-shadow: none;
  -webkit-box-shadow: 0 0 0px 1000px #a07de4 inset;
  box-shadow: 0 0 0px 1000px #a07de4 inset;
`;

const TextField = ({
  icon,
  type = "text",
  placeholder = "",
  style,
  value,
  onChange,
  error,
  toggle,
}) => {
  const [isOn, setOn] = React.useState(false);

  return (
    <Container
      style={{
        ...style,
      }}
      border={error && "1px solid red"}
    >
      <TextInput
        id="woiks"
        value={value}
        onChange={onChange}
        type={toggle && isOn ? "text" : type}
        placeholder={placeholder}
        style={{
          outline: "none",
          border: "0px",
          height: "80%",
          width: "90%",
          fontFamily: "robotoRegular",
          color: "white",
        }}
      />
    </Container>
  );
};

export default TextField;
