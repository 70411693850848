import React from "react";
import styled from "styled-components";
import ErrorText from "../../components/ErrorText";
import LegacyTextField from "../../components/LegacyTextField";
import { PASSWORD } from "../../navigation/CONSTANT";
import { useSendPasswordRecoveryCode } from "../../util/api/auth";


const TextButton = styled.p`
	margin: 20px;
	font-fammily: RobotoBold;
	cursor: pointer;
	color: #A9A9A9;
    
	&:hover {
		color: blue;
	}
`
const RootContainer = styled.div`
	width: 100vw;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

const Title = styled.h2`
	font-family: RobotoBold;
`;

const Description = styled.p`
	font-family: RobotoBold;
	font-size: 15px;
`;

const EmailView = ({ history }) => {
    const [ email, setEmail ] = React.useState('')
    const [ inputError, setInputError ] = React.useState(null)
    const sendPasswordRecoveryCodeMutation = useSendPasswordRecoveryCode()

    React.useEffect(() => {
        if (sendPasswordRecoveryCodeMutation.isSuccess) {
            history.push({
                pathname: PASSWORD,
                search: `?email=${email}`
            })
        }
    }, [sendPasswordRecoveryCodeMutation])

    const onSubmit = () => {
        if (validateEmail(email)) {
            sendPasswordRecoveryCodeMutation.mutate(email)
        } else {
            setInputError('Invalid Email')
        }
    }

	return (
        <RootContainer>

            <Title>Password Recovery</Title>
            <Description>Enter the email address associated with your VotingSmarter Account</Description>
            <LegacyTextField type="email" value={email} onChange={e => {
                setEmail(e.target.value)
                setInputError(null)
            }}/>
            <ErrorText error={sendPasswordRecoveryCodeMutation.error || inputError}/>
            <TextButton onClick={onSubmit}>Continue</TextButton>
        </RootContainer>
    );
};

const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

export default EmailView