import React from 'react'
import styled from 'styled-components'
import RpplLogo from '../../assets/rpplLogo.png'
import RoundButton from '../../components/RoundButton'
import { useHistory } from 'react-router-dom';
import {SURVEY} from '../../navigation/CONSTANT'

const RootContainer = styled.div`
    width: 100vw;
    height: 100vh;
    position: relative;
`

const Canvas = styled.img`
    position: absolute;
    width: 100%;
    height: 100%;
`


const LoginContainer = styled.div`
    background-color: white;
    display: block;
    position: relative;
    width: 30%;
    margin: auto;
    margin-top: 5%; 
    height: 85%; 

    box-shadow: 1px 5px 5px 1px  #808080;
    overflow:hidden;
    border-radius: 3%;
`


const HeaderText1 = styled.h1`
    margin-top: 5%;
    margin-bottom: 5%;
    font-family: RobotoBold;
    font-size: 2rem;
    text-align: center;
    color: #774082;
`

const HeaderText2 = styled.h1`
    line-height: 1.5;
    margin-bottom: 15%;
    margin-left: 15%;
    margin-right: 15%;
    font-family: RobotoLight;
    font-size: 1.5rem;
    text-align: center;
    color: #774082;
`
const Img = styled.img`
    margin: 10% 0 0 20%;
    max-width: 50%;
    max-height: 50%;
    width: auto;
    height: auto;
`
const Anchor = styled.a` 
    margin: 0% 0% 0% 32%;
    text-align: center;
    text-decoration: underline;
    font-family: RobotoBold;
    font-size: 2rem;
    color: #purple;
`


const HintView = () => {
    return (
        <RootContainer>
            <LoginContainer>
                <Img
                    src={RpplLogo} alt="Rppl Logo" />
                <HeaderText1>Welcome to VotingSmarter!</HeaderText1>
                <HeaderText2>Please complete our matching Quiz at the link below to start seeing how you match with companies. The Quiz is made up of partisan statements from all sides that are designed to elicit a response. The more honest you are, the better we can match you. There are no wrong or right answers, only what you believe!</HeaderText2>

                <Anchor href={SURVEY}>Take the Quiz!</Anchor>
            </LoginContainer>
        </RootContainer>

    )
}

export default HintView