import React from "react";
import { faEnvelope, faLock } from "@fortawesome/free-solid-svg-icons";
import { faGoogle } from "@fortawesome/fontawesome-free-brands";
import TextField from "../../components/TextField";
import RoundButton from "../../components/RoundButton";
import TextButton from "../../components/TextButton";
import { useForm, Controller } from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";
import AuthContext from "../../context/auth";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import styled from "styled-components";
import { CAPTCHA_KEY, GOOGLE_CLIENT_ID } from "../../util/CONSTANTS";
import { REDIR, SURVEY } from "../../navigation/CONSTANT";
import RpplMainBG from "./../../assets/rpplMainBG.png";
import RpplLogo from "./../../assets/newRpplLogo.png";
import { SIGN_IN } from "../../navigation/CONSTANT";
import { isMobile } from "react-device-detect";
import Modal from "react-modal";
import eula from "./../../assets/eula";

import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";

const appearDuration = 300;
const transitionName = `toggleTransition`;

const ErrorText = styled.p`
  font-family: MontserratMedium;
  font-size: 10px;
  color: red;
  text-align: center;
  padding: 0px;
`;

const WelcomeText = styled.p`
  font-family: GilroyBold;
  font-size: 2.5em;
  text-align: center;

  margin-top: 2px;
  margin-bottom: 0px;
  color: white;

  &.${transitionName}-appear {
    opacity: 0.01;
  }

  &.${transitionName}-appear-active {
    opacity: 1;
    transition: opacity ${appearDuration}ms ease-out;
  }
`;
const SecondWelcome = styled.p`
  font-family: GilroySemiBold;
  font-size: 2em;
  text-align: center;
  margin-top: 2px;
  color: white;
`;
const Img = styled.img`
  position: absolute;
  max-width: 300px;
  height: auto;
  /* vertical-align: middle; */
  display: block;
  margin-top: 1%;
  margin-left: 1%;
`;

const RootContainer = styled.div`
  width: 100vw;
  height: 100vh;
  position: relative;
`;

const Canvas = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
`;

const LoginContainer = styled.div`
  background-color: transparent;
  display: block;
  position: relative;
  width: 40%;
  /* height: 100%; */
  margin: auto;
  margin-top: 5%;
  overflow: hidden;
  border-radius: 3%;
`;

const Anchor = styled.a`
  margin-top: 2%;
  margin-bottom: 5%;
  text-align: center;
  text-decoration: none;
  font-family: RobotoBold;
  color: white;
  cursor: pointer;
  &:hover {
    color: #4431a7;
  }
  -webkit-transition: all 0.25s linear;
  transition: all 0.25s linear;
`;
const TOS = styled.a`
  margin-top: 2%;
  margin-bottom: 5%;
  text-align: center;
  text-decoration: none;
  font-family: RobotoBold;
  color: white;
  cursor: pointer;
  transition: opacity 500ms ease-in;
`;

const Headers = styled.a`
  margin-top: 2%;
  margin-bottom: 1%;
  margin-left: 4%;
  text-align: left;
  text-decoration: none;
  font-family: GilroySemiBold;
  color: white;
`;

const modalStyle = {
  content: {
    top: "50%",
    height: "75%%",
    left: "50%",
    right: "50%",
    width: "25%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    transform: "translate(-50%, -50%)",
  },
};

const schema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup
    .string()
    .required("No password provided.")
    .min(8, "Password is too short - should be 8 chars minimum.")
    .matches(/[a-zA-Z]/, "Password can only contain Latin letters."),
});

const HomeView = ({ history }) => {
  const { useSignIn, useSignUp, useGoogleSignIn } =
    React.useContext(AuthContext);
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [agreed, setAgreed] = React.useState(false);
  const signUpMutation = useSignUp();
  const signInMutation = useSignIn();
  const googleSignInMutation = useGoogleSignIn();

  const displayEula = eula;

  const responseGoogle = (res) => {
    googleSignInMutation.mutate(res);
  };

  const onSubmit = (values) => {
    if (agreed) {
      signUpMutation.mutate(
        {
          email: values.email,
          password: values.password,
        },
        {
          onSuccess: () => {
            signInMutation.mutate({
              email: values.email,
              password: values.password,
            });
          },
        }
      );
    }
  };

  React.useEffect(() => {
    if (isMobile) {
      history.push(REDIR);
    }
  }, []);

  React.useEffect(() => {
    if (signInMutation.isSuccess || googleSignInMutation.isSuccess) {
      history.push(SURVEY);
    }
  }, [signInMutation, googleSignInMutation]);

  return (
    <RootContainer>
      <Modal isOpen={modalIsOpen} contentLabel="TOS" style={modalStyle}>
        <h2
          style={{
            fontFamily: "MontserratMedium",
          }}
        >
          Terms Of Use
        </h2>
        <div
          style={{
            marginBottom: "20%",
            height: "200%",
          }}
        >
          <textarea
            style={{
              fontFamily: "MontserratMedium",
              overflowY: "scroll",
              width: "100%",
              height: "100%",
            }}
            defaultValue={displayEula}
          ></textarea>
        </div>
        <button
          onClick={() => {
            setIsOpen(false);
          }}
        >
          Close
        </button>
      </Modal>
      <Canvas src={RpplMainBG} alt="rpplBG" />
      <Img src={RpplLogo} alt="Rppl Logo" />
      <LoginContainer>
        <CSSTransitionGroup
          transitionName={transitionName}
          transitionAppear={true}
          transitionAppearTimeout={appearDuration}
        >
          <WelcomeText>JOIN NOW</WelcomeText>
        </CSSTransitionGroup>

        <SecondWelcome>Shop Your Values</SecondWelcome>

        <div
          style={{
            marginTop: "2%",
            width: "100%",
            flexDirection: "column",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <form
            onSubmit={handleSubmit(onSubmit)}
            style={{
              width: "70%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Headers>Email</Headers>
            <Controller
              control={control}
              name="email"
              render={({ field }) => (
                <TextField
                  error={errors.email?.message}
                  style={{
                    marginTop: "1px",
                    marginBottom: "15px",
                  }}
                  {...field}
                  color="#a07de4"
                  // placeholder="Email"
                />
              )}
            />

            {errors.email?.message && (
              <ErrorText>{errors.email?.message}</ErrorText>
            )}
            <Headers>Password</Headers>
            <Controller
              control={control}
              name="password"
              render={({ field }) => (
                <TextField
                  toggle={true}
                  type="password"
                  error={errors.password?.message}
                  style={{
                    marginTop: "1px",
                    marginBottom: "15px",
                  }}
                  {...field}
                  // placeholder="Password"
                />
              )}
            />

            {errors.password?.message && (
              <ErrorText>{errors.password?.message}</ErrorText>
            )}
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "20px",
              }}
            ></div>
            {signUpMutation.error && (
              <ErrorText>{signUpMutation.error}</ErrorText>
            )}

            <TOS>
              <input
                type="checkbox"
                agreed={agreed}
                onClick={() => setAgreed(true)}
              />
              I agree to the{" "}
              <Anchor
                onClick={() => {
                  window.open("https://votingsmarter.org/policies/", "_blank");
                }}
              >
                <u>Privacy Policy & Terms of Service</u>
              </Anchor>
            </TOS>

            <RoundButton
              type="submit"
              text="JOIN NOW"
              color="white"
              textColor="#4431A7"
            />

            <TextButton
              onClick={() => history.push(SIGN_IN)}
              text="Already a Member?"
              ultext="Sign&#8209;In"
              color="transparent"
              width="80%"
              padding="25px 0px 0px 0px"
            />
          </form>
        </div>
      </LoginContainer>
    </RootContainer>
  );
};

export default HomeView;
