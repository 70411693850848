import React from 'react';
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'

const MainContainer = styled.div`
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row,
    margin-bottom: 0px 0px 10px 0px;
    background-color: white;
`

const List = styled.ul`
    list-style-type: none;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    flex: 1;
    padding: 0px 100px 0px 100px;
`

const Anchor = styled.a`
    text-decoration: none;
    font-family: RobotoBold;
    color: #774082;
`

const Navbar = ({ visible, dict }) => {
    let { pathname } = useLocation()
    if (!visible) {
        return (null)
    }

    return (
        <MainContainer>
            <List>
                {dict[pathname] && dict[pathname].map(p => (
                    <li><Anchor href={p.href}>{p.text}</Anchor></li>
                ))
                }
            </List>
        </MainContainer>
    )
}

export default Navbar;